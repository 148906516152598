import React from "react"
import '../../scss/components/legals/articleMaker.scss'

/**
 * Permet de traiter le cas où il le document contient une liste
 * @param list La liste à traiter
 * @returns {JSX.Element}
 * @constructor
 */
function List({list, classId}) {
  return(
    <ul className="list-article">
      {
        list.map((el, i) => {
          if(el.title) {
            return(
              <>
                <li>{el.title}</li>
                <div>{el.ctn}</div>
              </>
            )
          }
          return <li className={classId !== undefined ? 'no-dot' : ''} key={el+i}>{el}</li>
        })
      }
    </ul>


  )
}

/**
 * Permet de gerer le cas où il y des liens à mettre en forme
 * @param ctn le paragraphe.
 * @returns {JSX.Element}
 * @constructor
 */
function ArticleWithLink({content}) {

  const {ctn,link} = content

  return(
    <>
      {ctn}
      <a href={`http://${link.href}`}>{link.name}</a>

    </>
  )
}

/**
 *
 * @param article l'article à mettre en page
 * @param prefixClass Peut être différent selon qu'il s'agit d'un article avec plusieurs niveaux ou non. (Utilisation de la récursivité)
 * @returns {JSX.Element}
 * @constructor
 */
export default function Article({article, prefixClass}) {
  const {_id, ancre, title, blockquote} = article

  return(
    <section className="article-section" id={ancre}>
      {
        _id !== '' ? prefixClass === "sub" ? <h2>{_id} : {title} </h2> : <h1>{_id} : {title} </h1> : ''
      }
      <div className={`${prefixClass}article-body`}>
        {
          blockquote.map((blck, index)=> {

            if(!blck._id) {
              if(blck.list) {
                return (
                  <>
                    <div key={index} className={blck.class ? `${blck.class} ${prefixClass}article-body__blck` : `${prefixClass}article-body__blck`}>{blck.link !== undefined ? blck.link : blck.ctn   }</div>
                    <List classId={blck.classList} list={blck.list} />
                  </>
                )
              }
              return <div key={index} className={blck.class ? `${blck.class} ${prefixClass}article-body__blck` : `${prefixClass}article-body__blck`}>{blck.link !== undefined ? <ArticleWithLink content={blck} /> : blck.ctn}</div>

            }

            return <Article article={blck} prefixClass="sub" key={blck._id} />
          })
        }
      </div>
    </section>
  )
}