import React from "react"

//Import Components
import LegalLayout from "../../components/legals/LegalLayout"
import Article from "../../components/legals/ArticleMaker"
import Summary from "../../components/legals/Summary"


//Import de fichiers
import  mentions_legal  from "../../utils/Legals/mentionLegales.json";

export default function Legal({location}) {

  return (
    <LegalLayout activeLink={location.pathname}>
      <div class="legal">
        <div className="legal__head"><h1>Mentions Légales</h1></div>
        {
          mentions_legal.fr.map((art) => {
            return <Article key={art.anchor} article={art} prefixClass="" />
          })
        }
      </div>
      <Summary tabTitle={Array.from(mentions_legal.fr, (el) => {return [el.ancre, el._id]}) }/>
    </LegalLayout>

  )

}

