import React, { useState } from "react"
import { Link } from "gatsby"

import '../../scss/components/legals/summary.scss'


/**
 * Permet d'afficher le sommaire du pdf sur la drotie
 * @param tabTitle Tableau des parties du document
 * @returns {JSX.Element}
 * @constructor
 */
export default function Summary({tabTitle}) {

  const [ancreClicked, setAncre] = useState()

  const handleChangeAncre = function(ancre) {
    setAncre( ancre)
  }

  return(
    <div className="summary">
      {
        tabTitle.map(([ancre,title]) => {
          return <div className={ancreClicked === ancre ? "isOnTop summary__line" : "summary__line"} key={ancre}>
            <Link to={`#${ancre}`} onClick={() => handleChangeAncre(ancre)}>{title}</Link>
          </div>
        })

      }
    </div>
  )
}